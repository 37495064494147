import React, { useEffect, useRef, useState } from "react";
import './index.scss';

const outputRef = React.createRef();
const IndexPage = () => {
  const inputRef = useRef();
  const [text, setText] = useState();
  useEffect(() => {

  }, []);

  const clearText = () => setText('');
  return (
    <div className="console">
      <div ref={outputRef} className="output">
        <span>Initializing...</span><br />
        <span className="green">0.0002ms ok! ^^</span><br />
        <span className="seperator">== == == == == == == == == == == == == == == == == ==</span><br />
        <pre>{`
          #((  ((#/ /##(    #((/*    (((# (#((  ((#(((#(### /(( #(#. .###*.  ##(  (((/ 
          (#( (((//(##(##( (((((((( (%(((# (((((#(*(((#(((#/(&# (((/.(((((## ((( #(((  
          ((((((. (((. #((.(((./(((/#(*#(#  /(#(#  *,/#(/.,,#(( (((/(((( (#( ##((((,   
          ######  ###( #%# (###### #######*  ###,    (###  *### ###  ######, ##%###    
          %%# #%#  #%##%%, ,%%(##. /%#.*##   *#%.    /##*   ##%%##%  %%#%#%  ##%.%%%   
           %.  /&   #%%*    %/  %%  %   ,#    %*      ((     .%##*   #%   %*  #.  *%   
        `}
        </pre>
        <span className="seperator">== == == == == == == == == == == == == == == == == ==</span>
        <br />
        <br />
        <span>Science is what we understand well enough to explain to a computer. Art is everything else we do.<br /><span className="red">Donald Knuth</span></span>
        <br />
        <br />
        <span className="blue">type '<span className="grey">help</span>' to see a list of comands available <br /> or '<span className="grey">contact</span>' for a list of ways to contact me.</span><br />
      </div>
      <div className="action">
        <span>dev$ </span>
        <textarea
          ref={inputRef}
          className="input"
          name="input"
          cols="30"
          rows="1"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              var inputVal = e.target.value.trim();
              if (inputVal === "help") {
                help();
                clearText();
              } else if (inputVal === "ping") {
                pong();
                clearText();
              } else if (inputVal === "about") {
                aboutMe();
                clearText();
              } else if (inputVal === "contact") {
                contactMe();
                clearText();
              } else if (inputVal === "clear") {
                clearConsole();
                clearText();
              } else if (inputVal.startsWith("say") === true) {
                sayThis(inputVal);
                clearText();
              } else if (inputVal === "time") {
                getTime();
                clearText();
              } else if (inputVal.startsWith("exit") === true) {
                Output('<span class="blue">Goodbye! Comeback soon.</span>');
              } else {
                Output('<span>command not found</span></br>');
                clearText();
              }
            }
          }}
        />
      </div>
    </div>
  );
}

export default IndexPage;

export const Head = () => <title>Koray Türk Personal Web Site</title>

function seperator() {
  Output('<span class="seperator">== == == == == == == == == == == == == == == == == ==</span></br>');
}

function clearConsole() {
  outputRef.current.innerHTML = '';
  Output('<span>clear</span></br>');
}

function help() {
  var commandsArray = ['Help: List of available commands', '>help', '>about', '>contact', '>ping', '>time', '>clear', '>say'];
  for (var i = 0; i < commandsArray.length; i++) {
    var out = '<span>' + commandsArray[i] + '</span><br/>'
    Output(out);
  }
}

function pong() {
  Output('<span>pong</span></br><span class="pong"><b class="left">|</b><b class="right">|</b></span></br>');
}

function sayThis(data) {
  data = data.substr(data.indexOf(' ') + 1);
  Output('<span class="green">[say]:</span><span>' + data + '</span></br>');
}

function getTime() {
  Output(`<span>${new Date().toString()}</span></br>`);
}

function aboutMe() {
  var aboutMeArray = [
    '>About:',
    'Hi There!',
    'I\'m Koray, working freelance as a full stack developer.',
    'I\'m using .NET Core for the backend and ReactJs for the frontend.',
    'I play games in my free time. I like watching scientific videos, series, movies.'
  ];
  seperator();
  for (var i = 0; i < aboutMeArray.length; i++) {
    var out = '<span>' + aboutMeArray[i] + '</span><br/>'
    Output(out);
  }
  seperator();
}

function contactMe() {
  var contactArray = [
    '>Contact:',
    '[GitHub](https://github.com/korayturk)',
    '[LinkedIn](https://tr.linkedin.com/in/korayturk)'
  ];
  seperator();
  for (var i = 0; i < contactArray.length; i++) {
    var out = '<span>' + contactArray[i] + '</span><br/>'
    Output(out);
  }
  seperator();
}

function Output(data) {
  outputRef.current.innerHTML += data;
}
